<template>
    <div>
        <v-dialog v-model="dialog" width="540" class="mx-auto">
            <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" @click="verificaCPF"> Esqueci minha senha </span>
            </template>
            <v-form ref="form">
                <v-card v-if="email">
                    <v-card-title class="mx-auto">
                        <v-row>
                            <v-col cols="11">CONFIRME O SEU E-MAIL</v-col>
                            <v-col cols="1">
                                <v-btn icon @click="dialog = false">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                Confirme o seguinte e-mail cadastrado no cpf {{ this.cpf }}: <b>{{ this.email }}</b>.
                            </v-col>
                        </v-row>
                        <v-row dense class="mb-0 pb-0">
                            <v-col cols="12">
                                <v-text-field outlined :loading="loading" :full-width="true" label="E-mail" required
                                    v-model="inputEmail" @keyup.enter="checaBotao" hide-details="true" clearable
                                    :rules="[e => e.endsWith(this.email.substring(this.email.indexOf('@')))]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-btn dark rounded color="primary" block @click="verificaEmail"> ENVIAR EMAIL
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card v-else>
                    <v-card-title class="mx-auto">
                        <v-row>
                            <v-col cols="11">USUÁRIO OU EMAIL NÃO CADASTRADO</v-col>
                            <v-col cols="1">
                                <v-btn icon @click="dialog = false">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-card-text>
                        <v-row>
                            <v-col cols="12" class="mb-0 py-0">
                                Entre em contato com o suporte caso isto seja um erro.
                            </v-col>
                            <v-col class="mb-0 py-0" cols=12>
                                <v-btn @click="redirectEmail" icon>
                                    <v-icon>mdi-email</v-icon>
                                </v-btn>
                                <span @click="redirectEmail">suporte@hro.med.br</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-form>

        </v-dialog>
    </div>
</template>

<script>
import { apiHost } from '../../http'
import axios from 'axios'
export default {
    name: 'RecuperaSenha',
    props: {
        itemCorrente: { type: String }
    },
    data: () => ({ dialog: false, email: '', inputEmail: '', cpf: '', loading: false }),
    methods: {
        redirectEmail() {
            if (this.itemCorrente) {
                location.href = `mailto:suporte@hro.med.br?${`subject=[INFORMATIVO] Recuperação de Senha&body=Solicito o cadastro do e-mail referente ao CPF ${this.itemCorrente}`.replace(' ', '%20')}`
            }
        },
        checaBotao(e) {
            if (e.keyCode == 13) {
                this.verificaEmail()
            }
        },

        verificaEmail() {
            if (this.$refs.form.validate()) {

                this.loading = true
                axios.post(`${apiHost}/contas/request-reset-email/`, { 'username': this.cpf, 'email': this.inputEmail })
                    .then((response) => {
                        // location = '/login'
                        alert(response.data.message)
                        this.loading = false
                    })
            }
        },
        verificaCPF() {
            axios.get(`${apiHost}/contas/request-email/?username=${this.itemCorrente}`)
                .then((response) => {
                    this.email = response.data.email
                    this.cpf = response.data.username
                })
        },
    },
}
</script>

